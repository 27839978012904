import { Box } from '@material-ui/core';
import QuarterDesktopView from '../components/common/QuarterDesktopView';

/* eslint-disable */
const Help = () => {
    return (
        <Box
          mt={2}
          py={3}
          px={{ xs: 2, sm: 3, md: 6 }}
          bgcolor="background.paper"
          borderRadius={6}
          justifyContent="center"
        >
            <h3>Help</h3>
            <Box maxWidth="700px">
              <iframe width="100%" height="350px" src="https://www.youtube.com/embed/xgrWXkEWoGs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>            
        </Box>
    )
}

export default Help
